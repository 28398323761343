import { PageContext } from "src/renderer/types";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
export interface Configuration {
  colorScheme: string;
  livekitUrl: string;
}

export interface StoreState {
  configuration: Configuration;
}

//runs on the server side
//pageContext.zustandInit is transfered to the browser in the initial html response
export const initializeStoreServer = (pageContext: PageContext) => {
  pageContext.zustandInit = {
    configuration: {
      colorScheme: "blue",
      livekitUrl: process.env.PUBLIC_LIVEKIT_WS_URL,
    },
  } as Partial<StoreState>;
  initializeStore(pageContext);
};

//runs on the browser side
export const initializeStore = (pageContext: PageContext) => {
  if (pageContext.zustandInit) {
    useStore.setState(pageContext.zustandInit);
  }
};

export const useStore = create<StoreState>()(
  immer(
    devtools((set) => ({
      configuration: {
        colorScheme: "blue",
        livekitUrl: "",
      },
    }))
  )
);
